<template>
  <div class="home">
    <img alt="Under Construction" src="../assets/PRTweb.png">
  </div>
</template>

<script>
export default {
  name: 'Home',
  components: {
    
  }
}
</script>

<style scoped>
  html,body {
    padding: 0;
    margin: 0;
  }
  /* ------------ Phone Screens - Collapsed ------------ */
	@media only screen and (min-width : 320px) and (max-width: 768px) {
    img {
      /* border: 1px solid green; */
      width: 100%;
      /* max-width: 509px; */
    }
	}
  /* ------------ Tablet Screens ------------ */
	@media only screen and (min-width: 768px) and (max-width: 992px){
		img {
      /* border: 1px solid blue; */
      width: 100%;
      /* max-width: 509px; */
    }
	}
	/* ------------ Desktop Screen ------------ */
	@media only screen and (min-width : 992px) {
		img {
      /* border: 1px solid red; */
      width: 100%;
      max-width: 509px;
    }
	}
</style>